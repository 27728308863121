<template>
  <div v-if="loaded">
      <offer-display  />
  </div>
</template>

<script>
import OfferDisplay from '@/components/UniversalComponents/Offer/OfferDisplay.vue';

export default {
  name: 'Offer Preview',
  components: {
    OfferDisplay,
  },
  data() {
    return { loaded: false };
  },
  beforeDestroy() {
    this.$store.dispatch('offer/clearOffer');
  },
  mounted() {
    const data = JSON.parse(this.$route.query.offer);
    this.$store.dispatch('offer/setOfferData', data);
    this.$router.replace('/offerPreview');
    this.loaded = true;
  },

};
</script>

<style>

</style>
